/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React from 'react';
import {ProductList} from '../../../gallery/components/ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript} from '../../../types/galleryTypes';
import s from '../../../gallery/components/GalleryApp/GalleryApp.scss';
import CategoriesClasses from './CategoriesApp.scss';
import classNames from 'classnames';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import stylesParams from '../../../components/GridGallery/stylesParams';
import 'wicg-inert';
import {CategoriesBreadcrumbs} from '../../../gallery/components/CategoriesBreadcrumbs/CategoriesBreadcrumbs';
import {Hero} from '../Hero/Hero';
import {SideFiltersInLayout} from '../../../gallery/components/layoutComponents/SideFiltersInLayout/SideFiltersInLayout';
import {SortInLayout} from '../../../gallery/components/layoutComponents/SortInLayout/SortInLayout';
import {MobileFiltersInLayout} from '../../../gallery/components/layoutComponents/MobileFiltersInLayout/MobileFiltersInLayout';
import {
  announceIfFilterResultChanged,
  shouldShowFiltersInLayout,
  shouldShowGalleryAppInLayout,
  shouldShowSortInLayout,
} from '../../../gallery/components/layoutComponents/appUtils';

export enum DataHook {
  Root = 'gallery-app-root',
  Content = 'gallery-app-content',
  Container = 'gallery-app-container',
  SideFilters = 'gallery-aside-filters',
  MobileContainer = 'mobile-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class CategoriesAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  public render() {
    const {useYoshiStyleParamStandard} = this.props.globals.experiments;
    let isFullWidth, responsive;
    if (useYoshiStyleParamStandard) {
      const {styles} = this.props.globals;
      isFullWidth = styles.get(stylesParams.full_width);
      responsive = styles.get(stylesParams.responsive);
    } else {
      isFullWidth = this.props.globals.styleParams.booleans.full_width;
      responsive = this.props.globals.styleParams.booleans.responsive;
    }
    const {shouldShowMobile} = this.props.globals;
    const isDesktop = !shouldShowMobile;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters = isDesktop && shouldShowFiltersInLayout(this.props.globals);
    const shouldRenderDesktopSort = isDesktop && shouldShowSortInLayout(this.props.globals);

    const classnames = {
      app: classNames({
        [s.galleryApp]: true,
        deviceMobile: shouldShowMobile,
        [CategoriesClasses.rightColumn]: true,
      }),
      content: classNames({
        [s.content]: true,
        [s.contentResponsive]: responsive,
        [s.fullWidth]: isFullWidth,
      }),
    };

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          <CategoriesBreadcrumbs />
          {shouldRenderSideFilters && (
            <div className={CategoriesClasses.leftColumn}>
              <SideFiltersInLayout {...this.props.globals} />
            </div>
          )}
          <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
            <div data-hook={DataHook.Content} className={classnames.content}>
              <div data-hook={DataHook.Container} className={s.container}>
                <Hero />
                {shouldRenderDesktopSort && <SortInLayout />}
                {shouldShowMobile && <MobileFiltersInLayout {...this.props.globals} />}
                <ProductList hasFilters={false} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const CategoriesApp = withGlobals(withTranslations()(CategoriesAppComp));
